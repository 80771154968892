<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md8>
                <v-card class="elevation-4" id="ForgotPassCard">
                    <h2 style="text-align:center">Forgot Password</h2>
                    <transition name="fade-transition"><v-alert
                        border="left"
                        :color="ResponseClass"
                        dark
                        v-if="!!responseMsg"
                    >
                        {{responseMsg}}
                    </v-alert></transition>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="12" sm="7">
                                    <v-form ref="userEmailforForgetPass" v-model="valid1" lazy-validation>
                                    <v-text-field prepend-icon="mail" name="userEmail" label="Email" type="email" required v-model="userEmail" :rules="emailRules" color="#777BD2"></v-text-field>
                                    </v-form>
                                </v-col>
                                <v-col cols="12" sm="5" class="send-verification-code">
                                    <v-btn dark @click="forgotPasswordSendCode" class="elevation-0">
                                        <v-progress-circular
                                            indeterminate
                                            color="#fff"
                                            v-if="verificationCodeInProgress"
                                            class="progressLoader"
                                        ></v-progress-circular><span v-if="!verificationCodeInProgress">Send Verification Code</span></v-btn>
                                </v-col>
                            </v-row>
                            <div class="verificationCodeStyle" :class="verificationClass">
                                <label>Verification Code</label>
                                <v-icon :class="verificationClass">verified_user</v-icon>
                                <CodeInput :loading="codeInputLoader" :fieldWidth="50" :fieldHeight="50" class="input" v-on:complete="onComplete" v-on:change="onChange"/>
                                <transition name="fade-transition"><div v-if="confirmationCodeMsg" class="confirmation-code-message" style="margin: 10px 33px 0px;">{{confirmationCodeMsg}}</div></transition>
                            </div>
                            <v-text-field prepend-icon="lock" name="newPassword" label="New Password" id="newPassword" type="password" required v-model="newPassword" :rules="passwordRules" color="#777BD2">
                            </v-text-field>
                            <v-text-field prepend-icon="lock" name="confirmPassword" label="Confirm Password" id="confirmPassword" type="password" required v-model="confirmPassword" :rules="confirmPasswordRule" color="#777BD2">
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn id="cancelTemplateBtn" @click="cancel" class="elevation-0">Cancel</v-btn>
                        <v-btn id="forgotPassBtn"  @click="forgotPasswordSubmit" class="primary elevation-0"><v-progress-circular
                            indeterminate
                            color="#fff"
                            v-if="forgotPasswordInProgress"
                            class="progressLoader"
                        ></v-progress-circular><span v-if="!forgotPasswordInProgress">Update Password</span></v-btn>
                        
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import CodeInput from "vue-verification-code-input";
export default {
    name: 'ForgotPassword',
    components: {
        CodeInput
    },
    data(){
        return{
            newPassword: '',
            confirmPassword: '',
            verification_code: '',
            userEmail: '',
            countDown : 10,
            valid: false,
            valid1: false,
            codeInputLoader: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= 6 ||
                    'Password must be greater than 6 characters',
                v => v.match(/[A-Z]/) != null || 'Password should have atleast one uppercase character',
                v => v.match(/[0-9]/) != null || 'Password should have atleast one numeric character',
                v => v.match(/[@$!%*#?&]/) != null || 'Password should have atleast one special character',
            ],
            confirmPasswordRule: [
                v => !!v || 'Type password again',
                v => v == this.newPassword || 'Password does not match'
            ],
            verificationClass: '',
            confirmationCodeMsg: '',
        }
    },
    mounted(){
        if(this.$store.state.user != null || this.$store.state.user != undefined){
            this.$router.push({path: '/'})
        }
        this.$store.state.responseMsg = '';
    },
    methods:{
        forgotPasswordSubmit() {
            this.$store.state.forgotPasswordInProgress = true;
            if (this.$refs.form.validate() && this.verification_code.length == 6 && this.$refs.userEmailforForgetPass.validate()) {
                this.confirmationCodeMsg = '';
                this.$store.dispatch('forgotPasswordSubmit', {
                    username: this.userEmail,
                    new_password: this.newPassword,
                    code: this.verification_code
                });
            }
            else if(!this.$refs.form.validate() && this.verification_code.length != 6 && !this.$refs.userEmailforForgetPass.validate()){
                this.verificationClass = 'error--text';
                this.confirmationCodeMsg = 'Confirmation code is required';
                this.$store.state.forgotPasswordInProgress = false;
            }
            else if(this.verification_code.length != 6){
                this.confirmationCodeMsg = 'Confirmation code is required';
                this.verificationClass = 'error--text';
                this.$store.state.forgotPasswordInProgress = false;
            }
            else if(!this.$refs.form.validate()){
                this.confirmationCodeMsg = '';
                this.$store.state.forgotPasswordInProgress = false;
            }
            else if(this.$refs.userEmailforForgetPass.validate()){
                this.confirmationCodeMsg = '';
                this.$store.state.forgotPasswordInProgress = false;
            }
            else{
                this.confirmationCodeMsg = '';
                this.$store.state.forgotPasswordInProgress = false;
            }
        },
        forgotPasswordSendCode(){
            this.$store.state.verificationCodeInProgress = true;
            if (this.$refs.userEmailforForgetPass.validate()) {
                this.$store.dispatch('forgotPasswordSendCode', {
                    username: this.userEmail,
                });
            }
            else{
                this.$store.state.verificationCodeInProgress = false;
            }
        },
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        onComplete(v) {
            this.verification_code = v;
        },
        onChange(v) {
            if(v.length != 6){
                this.confirmationCodeMsg = 'Confirmation code is required'
                this.verification_code = '';
                this.verificationClass = 'error--text'
            }
            else{
                this.confirmationCodeMsg = '';
                this.verificationClass = 'primary--text'
            }
        },
        cancel(){
            this.$router.push({path: '/sign-in'});
        }
    },
    computed:{
        responseMsg(){
            return this.$store.state.responseMsg;
        },
        ResponseClass(){
            return this.$store.state.ResponseClass;
        },
        forgotPasswordInProgress(){
            return this.$store.state.forgotPasswordInProgress;
        },
        sendCodeBtn(){
            var x = true;
            if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.userEmail) == true || this.verificationCodeInProgress == true){
                x = true;
            }
            else{
                x = false;
            }
            return x;
        },
        confirmationCodeEmpty(){
            var x = false;
            if(this.verification_code == '' || this.valid == false || this.forgotPasswordInProgress == true){
                x = true;
            }
            else{
                x = false;
            }
            return x;
        },
        verificationCodeInProgress(){
            return this.$store.state.verificationCodeInProgress
        }
        
    }
}
</script>
<style scoped>
button#forgotPassBtn{
    background-color: #777BD2;
    color: #fff;
}
.send-verification-code button.v-btn{
    min-width: 100%;
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
    background-color: #777BD2;
    transition: 0.3s all;
}
.send-verification-code button.v-btn:disabled{
    cursor: not-allowed;
    pointer-events: auto
}
.verificationCodeStyle{
    padding-top: 12px;
}
.verificationCodeStyle label{
    display: block;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 35px;
}
.verificationCodeStyle .v-icon{
    margin-right: 9px;
}
.verificationCodeStyle .react-code-input-container{
    display: inline-block;
}
.v-card__actions{
    padding-left: 20px;
}
.v-card__actions button{
    padding: 10px 30px !important;
    min-width: 220px !important;
}
.v-card__actions .secondary{
    background-color: #9D9B9B !important;
}
.verificationCodeStyle.error--text,.verificationCodeStyle.error--text .react-code-input-container>>>.react-code-input input{
    color: #ff5252 !important;
    border-color: #ff5252 !important;
    caret-color: #ff5252 !important;
}
.v-icon.primary--text,.verificationCodeStyle.primary--text,.verificationCodeStyle.primary--text .react-code-input-container>>>.react-code-input input{
    color: #777BD2 !important;
    caret-color: #777BD2 !important;;
    border-color: #777BD2 !important;
}
@media screen and (min-width: 960px){
    #ForgotPassCard{
        padding: 50px 90px 60px;
    }  
}
@media screen and (max-width: 959px) and (min-width: 600px){
    #ForgotPassCard{
        padding: 15px 30px 30px;
    }  
}
@media screen and (max-width: 600px){
    #ForgotPassCard{
        padding: 5px 30px 20px;
    }  
}
</style>